$purple-lighten-5: #ede7f6;
$purple-lighten-4: #d1c4e9;
$purple-lighten-3: #b39ddb;
$purple-lighten-2: #9575cd;
$purple-lighten-1: #7e57c2;
$purple: #673ab7;
$purple-darken-1: #5e35b1;
$purple-darken-2: #512da8;
$purple-darken-3: #4527a0;

$gray-lighten-5: #fafafa;
$gray-lighten-4: #f5f5f5;
$gray-lighten-3: #eeeeee;
$gray-lighten-2: #e0e0e0;
$gray-lighten-1: #bdbdbd;
$gray: #9e9e9e;
$gray-darken-1: #757575;
$gray-darken-2: #616161;
$gray-darken-3: #424242;
$gray-darken-4: #212121;

$nightmode-bg: rgb(49, 49, 49);

$form-error: #ff8a65;
$form-success: #81c784;
$form-error-dark: #ff8a65;
$form-success-dark: #6ddd62;

$pw-score-0: red;
$pw-score-1: orange;
$pw-score-2: rgb(80, 218, 67);
$pw-score-3: rgb(17, 182, 17);
$pw-score-4: rgb(8, 167, 8);

$rarity-common: rgb(226, 226, 226);
$rarity-uncommon: rgb(253, 216, 75);
$rarity-rare: rgb(98, 227, 255);
$rarity-epic: rgb(140, 67, 255);
$rarity-legendary: rgb(218, 119, 53);
