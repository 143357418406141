@import "../../styles/variables.scss";

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid $purple-lighten-2;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $purple-lighten-2 transparent transparent transparent;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

.nightMode .lds-ring div {
    border: 8px solid $purple-lighten-4;
    border-color: $purple-lighten-4 transparent transparent transparent;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lds-wrapper {
    margin-top: 50px;
}
